import axios from "axios";

const api='https://academic-billing-git-master-ashik7863.vercel.app/api';

export const baseURL = api;

let axiosInstance = axios.create({
  baseURL,
});

export default axiosInstance;