import React from 'react'
import ContentBody from '../ContentBody/ContentBody'

export default function Home() {
  return (
    <>
    <ContentBody/>
    </>
  )
}
