import logo from './logo.svg';
import './App.css';
import Rout from './Router/Router';

function App() {
  return (
 <>
 <Rout/>
 </>
  );
}

export default App;
